import * as $ from 'jquery';
import helper  from './libs/helper.js'
import headerFunction  from './libs/header.js';
import { gsap, TweenLite, TweenMax, MotionPathPlugin, ScrollToPlugin, ScrollTrigger } from "gsap/all";

gsap.registerPlugin(TweenLite, TweenMax, MotionPathPlugin, ScrollToPlugin, ScrollTrigger); 
window.$ = $

$(document).ready(() => {
	helper($).init()
	headerFunction($).init()
    
    $('.banner-slider .item img, .contact-info figure img, .box-detail .text-summary img, .store-event .boxx .right-box img').responsiveImage({
        type:"img"
    });

})

window.closePopup = function(id) {
    const idPop = $("#" + id),
        contentPop = idPop.find('.content-pop');

    TweenLite.to(idPop, 0.5, { css: {'display': 'none','opacity': '0','scale': '1'}, delay: 0.2,ease: 'Quart.easeOut'});
    TweenLite.to(contentPop, 0.5, {css: {'opacity': '0','scale': '0.9'},delay: 0,ease: 'Quart.easeOut'});
    $('body').removeAttr('style');
}

window.overlay_pop = function() {
    $('.overlay-pop').click(function(){
        TweenLite.to($('.wrap-pop'), 0.5, {css: {'display': 'none','opacity': '0','scale': '1'},delay: 0.2,ease: 'Quart.easeOut'});
        TweenLite.to($('.wrap-pop .content-pop'), 0.5, {css: {'opacity': '0','scale': '0.9'}, delay: 0,ease: 'Quart.easeOut'});
        $('body').removeAttr('style');
    });
}

$.fn.responsiveImage = function (s) {
    let e ={
        type:undefined
    }
    e = $.extend(e,s);
    let elem = this, etype,
            action = function () {
                if(e.type == undefined){
                    etype = "background";
                }else{
                    etype = e.type;
                }
                let window_width;
                window_width = $(window).width();
                elem.each(function () {
                    let flag = false;
                    let images_url;
                    if ($(this).attr('data-tag') == 'detail') {
                        if (window_width > 991 && $(this).attr('has_load') != 'large') {
                            images_url = $(this).attr('data-img-large');
                            $(this).attr('has_load', 'large');
                            flag = true;
                        } else if (window_width <= 991 && window_width >= 768 && $(this).attr('has_load') != 'medium') {
                            images_url = $(this).attr('data-img-medium');
                            $(this).attr('has_load', 'medium');
                            flag = true;
                        } else if (window_width < 768 && window_width >= 0 && $(this).attr('has_load') != 'small') {
                            images_url = $(this).attr('data-img-small');
                            $(this).attr('has_load', 'small');
                            flag = true;
                        }
                        if (images_url == undefined) {
                            images_url = $(this).attr('data-img-large');
                            $(this).attr('has_load', 'large');
                        }
                    }else{
                        if (window_width > 1280 && $(this).attr('has_load') != 'large') {
                            images_url = $(this).attr('data-img-large');
                            $(this).attr('has_load', 'large');
                            flag = true;
                        } else if (window_width <= 1280 && window_width >= 768 && $(this).attr('has_load') != 'medium') {
                            images_url = $(this).attr('data-img-medium');
                            $(this).attr('has_load', 'medium');
                            flag = true;
                        } else if (window_width < 768 && window_width >= 0 && $(this).attr('has_load') != 'small') {
                            images_url = $(this).attr('data-img-small');
                            $(this).attr('has_load', 'small');
                            flag = true;
                        }
                        if (images_url == undefined) {
                            images_url = $(this).attr('data-img-large');
                            $(this).attr('has_load', 'large');
                        }
                    }

                    if (flag){
                        if(etype == "background"){
                            $(this).css('background-image', 'url('+images_url+')');
                        }else{
                            $(this).attr('src', images_url);
                        }     
                    }     
                });

            }
    action();
   
    var resizeTimer;

    $(window).resize(function () {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(function () {
            action();
        }, 500);
    });
}