//import { gsap} from "gsap";

export default ($) => ({ 
  scrollHeader(){
    const act = function(){
      if($('.banner, .banner-slider').length){
        if($(window).scrollTop() > 80) {
          $('header, .banner, .banner-slider').addClass('float');
        } else {
          $('header, .banner, .banner-slider').removeClass('float');
        }	  
      } else {
        $('header, .banner, .banner-slider').addClass('float');
      }
    }
    act();
    $(window).scroll(function () {
          act();          
    });
	},
    init() {
        this.scrollHeader()
	}
})